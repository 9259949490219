import runTippy, { type Props } from 'tippy.js';

export type TippyProps = Partial<Omit<Props, 'trigger'>>;
interface TippyReturn {
	destroy: () => void;
	update: (newProps: TippyProps) => void;
}

type TippyAction = (element: HTMLElement, props?: TippyProps) => TippyReturn;

/**
 * Svelte action for rendering a [Tippy.JS](https://atomiks.github.io/tippyjs/) tooltip
 *
 * @example
 * ```svelte
 * <script lang="ts">
 *   import {tippy} from './tippy';
 *   import 'tippy.js/dist/tippy.css'; // optional
 * </script>
 * <button use:tippy={{content: 'Test'}}>Test</button>
```
 * @param element The element to target (omitted with use)
 * @param props [Tippy.js Props](https://atomiks.github.io/tippyjs/v6/all-props/)
 */
export const tippyAction: TippyAction = (element, props) => {
	const { destroy, setProps: update } = runTippy(element, props);
	return {
		destroy,
		update
	};
};
/**
 * @example
 * ```svelte
 * <script lang="ts">
 *   import {tippy} from './tippy';
 *   const tippy = createTippy({
 *    arrow: false,
 *    offset: [0, 10],
 *    animateFill: true,
 *    delay: 10
 * })
 * </script>
 * <button use:tippy={{content: 'Test'}}>Test</button>
 * ```
 * ------------------------------------------
 * @param defaultProps The default properties to pass to tippy.js see [tippy.js props](https://atomiks.github.io/tippyjs/v6/all-props/)
 * @returns A svelte action for rendering the tippy.js tooltip
 */
export const createTippy: (defaultProps: TippyProps) => TippyAction =
	(defaultProps) => (element, props) => {
		return tippyAction(element, { ...props, ...defaultProps });
	};

/**
 * This file contains the implementation of the Tippy.js library for tooltips.
 * It exports a `tippy` object that can be used to create tooltips with custom options.
 *
 * @see https://www.npmjs.com/package/svelte-tippy
 * @see https://atomiks.github.io/tippyjs/
 */
export const tippy = createTippy({
	arrow: true,
	delay: [500, 250],
	theme: 'light',
	touch: false
});

export const touchTippy = createTippy({
	arrow: true,
	delay: [500, 250],
	theme: 'light',
	touch: true
});
